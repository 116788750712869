import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './footer.css';
import AusFlag from '../../images/Australian-flag.svg'
import USAFlag from '../../images/American-flag.svg'
import IndianFlag from '../../images/Indian-flag.svg'
import linkedin from '../../images/linkedin.svg'
import facebook from '../../images/fb.svg'
import instagram from '../../images/instagram.svg'
import youtube from '../../images/youtube.svg'

const year = new Date().getFullYear();

function Footer() {

    return (
        <footer className="footer">
            <Container fluid>
                <Row className="footer-addresses">
                    <Col xs="12" md="6" className="pb-3 pb-lg-0">
                        <Row className="gap-3 gap-lg-0">
                            <Col xs="12" className="text-center text-lg-start">
                                <p className="mb-4 ft-wt-600 block-title">Global Presence</p>
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="address-item">
                                    <div className="flag-circle">
                                        <img src={USAFlag} alt='USA Flag' loading="lazy" />
                                    </div>
                                    <div className="address-info">
                                        <p className="ft-wt-500">ACube USA LLC</p>
                                        <h6>401 Ryland St. STE 200-A,<br></br> Reno, WASHOE COUNTY,<br></br> NV 89502 USA</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="address-item">
                                    <div className="flag-circle">
                                        <img src={AusFlag} alt='Aus Flag' loading="lazy" />
                                    </div>
                                    <div className="address-info">
                                        <p className="ft-wt-500">Australia</p>
                                        <h6>ACubetech solutions<br></br> Engenesis Level 14, <br></br>275 Alfred Street North Sydney,<br></br> New South Wales 2060<br></br> Australia 02 91880844</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6" className="mt-4 mt-md-0 pb-3 pb-lg-0">
                        <Row className="gap-3 gap-lg-0">
                            <Col xs="12" className="text-center text-lg-start">
                                <p className="mb-4 ft-wt-600 block-title">Offices and Development Centers</p>
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="address-item">
                                    <div className="flag-circle">
                                        <img src={IndianFlag} alt='India Flag' loading="lazy" />
                                    </div>
                                    <div className="address-info">
                                        <p className="ft-wt-500">Bangalore</p>
                                        <h6>ACubetech solutions Pvt Ltd<br></br>Brigade IRV, 3rd Floor.<br></br>Nellurhalli, Whitefield,<br></br>Bangalore - 560066, India.</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="address-item">
                                    <div className="flag-circle">
                                        <img src={IndianFlag} alt='India Flag' loading="lazy" />
                                    </div>
                                    <div className="address-info">
                                        <p className="ft-wt-500">Chennai</p>
                                        <h6>ACubetech solutions Pvt Ltd<br></br>Akshaya CUBE, 6th Floor.<br></br>OMR Thoraipakkam, Near BSR Mall,<br></br>Chennai - 600096, India.</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="gap-3 gap-lg-0">
                    <Col xs="12" className="text-center text-lg-start">
                        <p className="mb-4 ft-wt-600 block-title">On-Call Support</p>
                    </Col>
                    <Col xs="12" lg="8" xl="10">
                        <Row className=" gap-3 gap-xl-0">
                            <Col xs="12" xl="4">
                                <div className="d-flex align-items-center gap-3 justify-content-center justify-content-lg-start">
                                    <div className="flag-circle">
                                        <img src={USAFlag} alt="USA Flag" loading="lazy" />
                                    </div>
                                    <div className="address-info">
                                        <a href="tel:+17026054713"><h4>+1 (702) 605-4713</h4></a>
                                        <a href="tel:+12014654533"><h4>+1 (201) 465-4533</h4></a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" xl="4">
                                <div className="d-flex align-items-center gap-3 justify-content-center justify-content-lg-start">
                                    <div className="flag-circle">
                                        <img src={IndianFlag} alt="India Flag" loading="lazy" />
                                    </div>
                                    <div className="address-info">
                                        <a href="tel:+919731800800"><h4>+91 9731 800 800</h4></a>
                                        <a href="tel:+918042021000"><h4>+91 8042 021 000</h4></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="footer-divider" />
                <Row className="footer-bottom align-items-center gap-4 gap-lg-0">
                    <Col xs="12" lg="3">
                        <div className="social-icons justify-content-center justify-content-lg-start">
                            <a href="https://www.linkedin.com/company/acubetech-solutions-private-limited/mycompany/?viewAsMember=true" rel="noreferrer" target="_blank" className="social-icon">
                                <img src={linkedin} alt="linkedin" loading="lazy" />
                            </a>
                            <a href="https://www.instagram.com/acubetech/" className="social-icon" rel="noreferrer" target="_blank" >
                                <img src={instagram} alt="instagram" loading="lazy" />
                            </a>
                            <a href="https://www.youtube.com/@acubetechnologies" className="social-icon" rel="noreferrer" target="_blank" >
                                <img src={youtube} alt="youtube" loading="lazy" />
                            </a>
                            <a href="https://www.facebook.com/Acubetech/" className="social-icon" rel="noreferrer" target="_blank" >
                                <img src={facebook} alt="facebook" loading="lazy" />
                            </a>
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="text-center">
                        <h6 className="mb-0 ft-wt-500 opacity-70">Copyright © {year} Acube. All Rights Reserved. Privacy Policy</h6>
                    </Col>
                    <Col xs="12" lg="3" className="text-center text-lg-end">
                        <a href={`${process.env.PUBLIC_URL}/Acube-brochure.pdf`} download>
                            <Button variant="secondary" className="text-uppercase">Download Brochure</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
